import React from "react";
import { useClickOutside } from "hooks/useClickOutside";
import clsx from "clsx";
import { IconArrow } from "icons";
const Dropdown = ({ children, options, isToggle, renderItem, ...etc }: any) => {
  const [show, setShow] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setShow(false);
  });

  const onClick = (value: any) => {
    if (!isToggle) {
      setShow(false);
      etc.onClick(value);
    }
  };

  function onClose() {
    setShow(false);
  }

  return (
    <div className="relative" ref={containerRef}>
      <div className={clsx("flex items-center gap-[5px] cursor-pointer", show ? "active" : "")} onClick={() => setShow(!show)}>
        {children}
        <IconArrow className={clsx("w-3 h-3 transition-all duration-200", show ? "-rotate-90" : "rotate-90")} />
      </div>
      {show ? (
        <ul className={clsx("absolute right-0 top-full mt-1 flex flex-col bg-bg border border-gray rounded-[4px] overflow-hidden z-10", etc.className, !etc.hideDivider ? "divide-y divide-gray" : "")}>
          {options.map((item: any, k: any) => {
            return renderItem ? (
              renderItem(item, onClose)
            ) : (
              <li key={k} onClick={() => onClick(item.value)} className="flex items-center justify-between cursor-pointer px-4 py-3 text-white hover:bg-bg-light">
                <div className="flex w-full body-medium whitespace-nowrap">{item.text}</div>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default Dropdown;
