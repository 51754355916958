import React from "react";
import Image from "components/Carousel/components/Image";
import { useImagePopup } from "../ImagePopupContext";

const RightSide = () => {
  const { data, onSelectImage, currentImageIdx } = useImagePopup();

  const LoadingCarousel = () => {
    return (
      <>
        <div className="rounded-[4px] w-[60px] h-[60px] bg-white bg-opacity-10" />
        <div className="rounded-[4px] w-[60px] h-[60px] bg-white bg-opacity-10" />
        <div className="rounded-[4px] w-[60px] h-[60px] bg-white bg-opacity-10" />
        <div className="rounded-[4px] w-[60px] h-[60px] bg-white bg-opacity-10" />
      </>
    );
  };

  const renderCarousel = () =>
    data.image_path.map((item, index) => (
      <div className={`cursor-pointer rounded-[4px] w-[60px] h-[60px] hover:opacity-100 bg-white bg-opacity-10  ${currentImageIdx === index ? "outline outline-white" : "opacity-50"}`} key={index}>
        <img loading="lazy" src={item} onClick={() => onSelectImage(index)} className="rounded-[4px] object-contain w-full h-full" />
      </div>
    ));

  return (
    <div className="flex flex-col h-full items-center gap-2.5">
      <Image />
      <div className="flex-center overflow-hidden gap-2.5 p-2.5 w-fit flex-wrap rounded-[10px] bg-black bg-opacity-60">{data.image_path.length !== 0 ? renderCarousel() : LoadingCarousel()}</div>
    </div>
  );
};

export default RightSide;
