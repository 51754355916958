import React from "react";
import Modal from "components/Modal/Modal";
import LeftSide from "./components/LeftSide";
import RightSide from "./components/RightSide";
import { useAppSelector } from "store";
import { useImagePopup } from "./ImagePopupContext";
import { Helmet } from "react-helmet";

const ImagePopup = () => {
  const { showImagePopup } = useAppSelector((state) => state.common);
  const { onClose, data } = useImagePopup();

  return (
    <Modal onClose={onClose} show={showImagePopup}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Refabric - {data.prompt}</title>
        <meta name="description" content={data.prompt} />
        <meta name="og:image" content={data.image_path[0]} />
        <meta name="og:title" content={data.prompt} />
        <meta name="og:url" content={location.href} />
        <meta name="og:type" content="website" />
        <link rel="canonical" href={location.href} />

        {/*Facebook */}
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Refabric facebook" />
        <meta property="og:description" content="Refabric facebook" />
        <meta property="og:image" content={data.image_path[0]} />

        {/*Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={location.origin} />
        <meta property="twitter:url" content={location.href} />
        <meta name="twitter:title" content="Refabric twitter" />
        <meta name="twitter:description" content="Refabric twitter" />
        <meta name="twitter:image" content={data.image_path[0]} />
      </Helmet>
      <div className="flex p-5 gap-10 h-full">
        <div className="flex flex-col overflow-auto no-scrollbar">
          <LeftSide />
        </div>
        <RightSide />
      </div>
    </Modal>
  );
};

export default ImagePopup;
