import React from "react";
import clsx from "clsx";
import { IconExplore, IconGenerate, IconHistory, IconPricing, Iconlike } from "icons";
import { Link } from "react-router-dom";
import { PATHS } from "../../router/config/paths";

const MobileMenuBar = () => {
  return (
    <div className="fixed w-screen bottom-0 z-50">
      <div className="flex w-full justify-between text-h7 text-grey bg-bg border-t border-border py-2.5 px-5">
        <Link
          to={PATHS.EXPLORE}
          className={clsx(
            "flex flex-col w-[75px] items-center gap-2.5",
            location.pathname === PATHS.EXPLORE ? "text-green before:absolute before:top-0 before:h-[2px] before:w-[75px] before:bg-green" : ""
          )}
        >
          <IconExplore />
          Explore
        </Link>
        <Link
          to={PATHS.CREATE}
          className={clsx(
            "flex flex-col w-[75px] items-center gap-2.5",
            location.pathname.includes(PATHS.CREATE) ? "text-green before:absolute before:top-0 before:h-[2px] before:w-[75px] before:bg-green" : ""
          )}
        >
          <IconGenerate />
          Create
        </Link>
        <Link
          to={PATHS.FAVORITES}
          className={clsx(
            "flex flex-col w-[75px] items-center gap-2.5",
            location.pathname.includes(PATHS.FAVORITES) ? "text-green before:absolute before:top-0 before:h-[2px] before:w-[75px] before:bg-green" : ""
          )}
        >
          <Iconlike className="fill-none" />
          Favorites
        </Link>
        <Link
          to={PATHS.HISTORY}
          className={clsx(
            "flex flex-col w-[75px] items-center gap-2.5",
            location.pathname.includes(PATHS.HISTORY) ? "text-green before:absolute before:top-0 before:h-[2px] before:w-[75px] before:bg-green" : ""
          )}
        >
          <IconHistory />
          History
        </Link>
        <Link
          to={PATHS.PRICING}
          className={clsx(
            "flex flex-col w-[75px] items-center gap-2.5",
            location.pathname.includes(PATHS.PRICING) ? "text-green before:absolute before:top-0 before:h-[2px] before:w-[75px] before:bg-green" : ""
          )}
        >
          <IconPricing />
          Pricing
        </Link>
      </div>
    </div>
  );
};

export default MobileMenuBar;
