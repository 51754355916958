import React from "react";
import ImagePopupProvider from "./ImagePopupContext";
import ImagePopup from "./ImagePopup";

const ImagePopupProv = () => {
  return (
    <ImagePopupProvider>
      <ImagePopup />
    </ImagePopupProvider>
  );
};

export default ImagePopupProv;
