import React from "react";
import { PATHS } from "./paths";
import Layout from "pages/Layout/Layout";

const Home = React.lazy(() => import("pages/Home"));
const Explore = React.lazy(() => import("pages/Explore"));
const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
const Pricing = React.lazy(() => import("pages/Pricing"));
const Account = React.lazy(() => import("pages/Account"));
const Create = React.lazy(() => import("pages/Create"));
const History = React.lazy(() => import("pages/History"));
const Favorites = React.lazy(() => import("pages/Favorites"));
const PrivacyPolicy = React.lazy(() => import("pages/PrivacyPolicy"));
const PaymentSuccess = React.lazy(() => import("pages/PaymentSuccess"));
const PaymentFail = React.lazy(() => import("pages/PaymentFail"));
const Promo = React.lazy(() => import("pages/Promo"));
const PostMobile = React.lazy(() => import("pages/PostMobile"));
const TermsOfUse = React.lazy(() => import("pages/TermsOfUse"));
const EmailVerified = React.lazy(() => import("pages/EmailVerified"));
const Welcome = React.lazy(() => import("pages/Welcome"));

export interface RouteConfig {
  path: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
  layout?: React.ElementType;
  layoutProps?: {
    hiddenFooter: boolean;
  };
  needLogin?: boolean;
  requireLogin?: boolean;
  children?: RouteConfig[];
  isResponsive?: boolean;
  mobileComponent?: React.LazyExoticComponent<() => JSX.Element>;
}

export const ROUTES: RouteConfig[] = [
  // {
  //   path: PATHS.HOME,
  //   component: Home,
  //   needLogin: false,
  //   layout: Layout,
  // },
  {
    path: PATHS.EXPLORE,
    component: Explore,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.POST,
    component: Explore,
    needLogin: false,
    layout: Layout,
    mobileComponent: PostMobile,
  },
  {
    path: PATHS.NEW_PASSWORD,
    component: ResetPassword,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.PRICING,
    component: Pricing,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.ACCOUNT,
    component: Account,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.CREATE,
    component: Create,
    needLogin: false,
    layout: Layout,
  },
  {
    path: PATHS.HISTORY,
    component: History,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.FAVORITES,
    component: Favorites,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.PRIVACY_POLICY,
    component: PrivacyPolicy,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.PAYMENT_SUCCESS,
    component: PaymentSuccess,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.PAYMENT_FAIL,
    component: PaymentFail,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.Promo,
    component: Promo,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.TERMS_OF_USE,
    component: TermsOfUse,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.EMAIL_VERIFY,
    component: EmailVerified,
    needLogin: true,
    layout: Layout,
  },
  {
    path: PATHS.WELCOME,
    component: Welcome,
    needLogin: false,
    layout: Layout,
  },
];
