import React from "react";

import Header from "./Header";
import ErrorModal from "./ErrorModal";
import EditProfileModal from "components/EditProfileModal/EditProfileModal";
import ChangePasswordModal from "components/ChangePasswordModal/ChangePasswordModal";
import DeleteAccountModal from "components/DeleteAccountModal/DeleteAccountModal";
import LogoutModal from "components/LogoutModal/LogoutModal";
import UserFlow from "components/UserFlow/UserFlow";
import Snackbar from "components/Snackbar/Snackbar";
import LeaveFeedback from "components/LeaveFeedback/LeaveFeedback";
import MobileMenuBar from "components/MobileMenuBar/MobileMenuBar";
import { useIsMobile } from "hooks/useIsMobile";
import PromoBanner from "../../components/PromoBanner";

interface Props {
  children: React.ReactNode;

  [key: string]: any;
}

const shouldRenderPromo = false;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Layout = ({ children, ...etc }: Props) => {
  const isMobile = useIsMobile();
  // const { search } = useLocation();
  // const params = new URLSearchParams(search);
  // const token = params.get("token");

  // console.log({ token });

  return (
    <main className="bg-bg min-h-screen flex flex-col relative">
      {!isMobile && shouldRenderPromo && <PromoBanner />}
      <Header hasPromo={shouldRenderPromo} />
      <EditProfileModal />
      <ChangePasswordModal />
      <DeleteAccountModal />
      <LogoutModal />
      <UserFlow />
      <Snackbar />
      <LeaveFeedback />
      {children}
      {isMobile && <MobileMenuBar />}
      <ErrorModal />
    </main>
  );
};

export default Layout;
