import React, { useState } from "react";
import "./Header.css";
import { IconRefabricLogo, IconSelectedStar, LogoRefabric } from "icons";
import Button from "components/Button/Button";
import clsx from "clsx";
import AvatarMenu from "components/AvatarMenu/AvatarMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNoReset, setShowSearchBar, setUser, toggleUserFlow } from "store/commonSlice";
import { useAppSelector } from "store";
import userService from "api/user/user.service";
import { resetCreateFormValues } from "store/createSlice";
import { useIsMobile } from "hooks/useIsMobile";
import { AnalyticsEvent } from "utils";
import { PATHS } from "../../../router/config/paths";
import {
  CLIENT_ID_LOCAL_STORAGE_KEY,
  WWW_DESIGN_SOLUTIONS,
  WWW_E_COMMERCE_SOLUTIONS,
  WWW_FASHION_PHOTOGRAPHY,
  WWW_REFABRIC_BASIC,
  WWW_SITE_LOGIN,
  WWW_SITE_PRICING_URL,
  WWW_SITE_URL,
} from "utils/constants";
import Dropdown from "components/Dropdown";
import { getAuthTokenFromLocalStorage } from "hooks/useAuthToken";
import { useLocalStorage } from "hooks/useLocalStorage";

export enum PageType {
  Explore = "/",
  Create = "/create",
  Pricing = "/pricing",
}

const Header = ({ hasPromo }: { hasPromo: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const [initTab, setInitTab] = React.useState<any>(location.pathname);
  const { isAuthorized, user, isCheckingToken, noReset } = useAppSelector((state) => state.common);

  const toggleDropdown = () => {
    AnalyticsEvent("profile_tapped", "", "click");
    setIsOpen(!isOpen);
  };
  React.useEffect(() => {
    if (location.pathname !== initTab) {
      setInitTab(location.pathname);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (user.id !== "") {
      userService
        .getUserInfo({ uid: user?.id })
        .then((res: any) => dispatch(setUser({ id: res.user_info.id, email: res.user_info.email, name: res.user_info.name, username: res.user_info.username, work: res.user_info.work })));
    }
  }, [isAuthorized]);

  const onChange = (selectedTab: any) => {
    if (selectedTab === PageType.Create) {
      if (!noReset) {
        dispatch(resetCreateFormValues());
      }
      dispatch(setNoReset(false));
    }
    if (selectedTab == PageType.Explore) {
      dispatch(setShowSearchBar(true));
    }
    navigate(selectedTab);
  };

  const renderItem = (item: any, onClose: () => void) => {
    const Icon = item?.icon;

    function onClick() {
      item.onClick();
      if (onClose) onClose();
    }

    return (
      <li key={item.value} className={clsx("flex w-full item-center cursor-pointer hover:text-green", item.isPro ? "pl-3" : "", item.isSelected ? "text-green" : "text-white")} onClick={onClick}>
        <div className="flex w-full items-center justify-between py-[14px] px-4">
          <span className="text-bodyMd">{item.text}</span>
          {Icon && <Icon className="w-5 h-5" />}
        </div>
      </li>
    );
  };

  const renderLinks = () => {
    return (
      !isMobile && (
        <div className="flex gap-[30px] text-[17px] font-normal leading-normal text-grey">
          <Link to={PATHS.CREATE} onClick={() => AnalyticsEvent("create_tab", "", "click")} className={clsx("flex items-center gap-2.5", location.pathname.includes("create") ? "text-green" : "")}>
            {location.pathname.includes("create") && <IconSelectedStar />}
            Create
          </Link>
          <Link to={PATHS.EXPLORE} onClick={() => AnalyticsEvent("explore_tab", "", "click")} className={clsx("flex items-center gap-2.5", location.pathname === "/explore" ? "text-green" : "")}>
            {location.pathname === "/explore" && <IconSelectedStar />}
            Explore
          </Link>
          <a
            onClick={() => {
              navigate(WWW_SITE_PRICING_URL);
              AnalyticsEvent("pricing_tab", "", "click");
            }}
            className={clsx("flex items-center gap-2.5", location.pathname.includes("pricing") ? "text-green" : "")}
          >
            {location.pathname.includes("pricing") && <IconSelectedStar />}
            Pricing
          </a>
          <Dropdown
            options={[
              {
                text: "Refabric Basic",
                onClick: () => {
                  navigate(WWW_REFABRIC_BASIC);
                },
              },
              {
                text: "Refabric Pro",
                onClick: () => {
                  navigate(WWW_DESIGN_SOLUTIONS);
                },
              },
              {
                text: "Design Solutions",
                onClick: () => {
                  navigate(WWW_DESIGN_SOLUTIONS);
                },
              },
              {
                text: "Content Marketing",
                onClick: () => {
                  navigate(WWW_FASHION_PHOTOGRAPHY);
                },
              },
              {
                text: "E-commerce Solutions",
                onClick: () => {
                  navigate(WWW_E_COMMERCE_SOLUTIONS);
                },
              },
            ]}
            className="!border-x-0 !border-b-0 !border-t-green !w-[200px] !bg-bg-2 !left-0 !rounded-t-none"
            isToggle={true}
            renderItem={renderItem}
            hideDivider={true}
          >
            <div className="flex">Products</div>
          </Dropdown>
        </div>
      )
    );
  };

  const renderCreateButton = () => {
    return (
      !isMobile &&
      location.pathname !== PageType.Create && (
        <Button
          className="btn-primary"
          onClick={() => {
            AnalyticsEvent("create_button_header", "", "click");
            navigate(PageType.Create);
          }}
        >
          Create
        </Button>
      )
    );
  };

  return (
    <div style={{ boxShadow: "0px 1px 0px 0px #2B2B2B" }} className={clsx("flex sticky top-0 z-50 w-full flex-col lg:border-b lg:border-border")}>
      <div className={clsx(" container-fluid-header flex items-center bg-bg w-full justify-between lg:px-4")}>
        <div className="flex items-center gap-5 mobile:py-[23px] py-[15px]">
          {isMobile ? (
            <IconRefabricLogo
              onClick={() => {
                AnalyticsEvent("header_click_event", "", "click");
                navigate(WWW_SITE_URL);
                // onChange(PageType.Explore);
              }}
            />
          ) : (
            <LogoRefabric
              className="cursor-pointer"
              onClick={() => {
                AnalyticsEvent("header_click_event", "", "click");
                navigate(WWW_SITE_URL + "?token=" + getAuthTokenFromLocalStorage() + "&client_id=" + useLocalStorage().getItem(CLIENT_ID_LOCAL_STORAGE_KEY));
                // onChange(PageType.Explore);
              }}
            />
          )}
          {renderLinks()}
        </div>
        <div className="flex relative items-center gap-2.5">
          {isCheckingToken ? (
            <></>
          ) : !isAuthorized ? (
            <>
              <Button
                className="btn-secondary"
                onClick={() => {
                  // dispatch(toggleUserFlow())
                  navigate(WWW_SITE_LOGIN);
                }}
              >
                Login
              </Button>

              <Button
                className="btn bg-green"
                onClick={() => {
                  // dispatch(toggleUserFlow());
                  navigate(PATHS.CREATE);
                }}
              >
                Try now - It&apos;s free
              </Button>
            </>
          ) : (
            <>
              {renderCreateButton()}
              {user.username !== "" && (
                <Button
                  disabled={isOpen}
                  onClick={toggleDropdown}
                  className="flex-center text-h5 text-white py-2.5 px-4 rounded-full bg-white bg-opacity-10 border border-white border-opacity-10 uppercase"
                >
                  {user?.username?.slice(0, 1)}
                </Button>
              )}
              <div className="absolute top-[56px] right-0">{isOpen && <AvatarMenu setIsOpen={setIsOpen} />}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
