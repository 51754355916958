import { MVPrefabricURL, MintgramURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { ICreateImageRequest, IMakeFeatured } from "./create.type";
import { AnalyticsEvent } from "utils";

export default {
  async create(data: ICreateImageRequest): Promise<ApiResponse<any>> {
    return await MintgramURL.post("v1/create_image", data, {});
  },
  async makeFeatured(data: IMakeFeatured): Promise<ApiResponse<any>> {
    AnalyticsEvent("make_featured", "", "click");

    return await MintgramURL.post("v1/mark_first", data, {});
  },
  async getCreateStatus(id: string): Promise<any> {
    return await MintgramURL.get(`v1/images_status/${id}`, {});
  },
  async outPaint(data: any): Promise<any> {
    return await MVPrefabricURL.post(`v1/create_outpaint`, data, {});
  },
  async deleteImage(data: any): Promise<any> {
    AnalyticsEvent("design_deleted", "", "click");

    return await MintgramURL.post(`v1/images/delete`, data, {});
  },
};
