/* eslint-disable @typescript-eslint/no-unused-expressions */
import clsx from "clsx";
import { useClickOutside } from "hooks/useClickOutside";
import { IconFacebook, IconLink, IconPinterest, IconShare, IconTwitter, IconWhatsapp } from "icons";
import React, { useState } from "react";
import { AnalyticsEvent, clipboardCopy } from "utils";

const PinterestShareButton = ({ className, imageUrl, description, url }: any) => {
  React.useEffect(() => {
    // This function initializes the Pinterest SDK
    window.PinUtils && window.PinUtils.build();
  }, []);

  const handlePinItClick = () => {
    // Trigger the Pinterest save action
    window.PinUtils &&
      window.PinUtils.pinOne({
        url: url,
        media: imageUrl,
        description: description,
      });
  };

  return (
    <ul className={className} onClick={handlePinItClick}>
      Share with Pinterest <IconPinterest className="w-5 h-5" />
    </ul>
  );
};

const ShareMenu = ({ data, setIsOpen, className, currentImageIdx }: { currentImageIdx: number; data: any; setIsOpen?: any; className?: string }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isCopied, setIsCopied] = useState(false);

  useClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  function handleTwitter(event: any) {
    event?.stopPropagation();

    AnalyticsEvent("share_twitter", "", "click");
    console.log(data);

    const imageURL = data.image_path[currentImageIdx]; // Replace this with the URL of the image you want to tweet
    let websiteURL;
    if (data.created_data) websiteURL = location.protocol + "//" + location.host + data.created_data.slug + "_" + currentImageIdx;
    else websiteURL = location.protocol + "//" + location.host + data.slug + "_" + currentImageIdx;

    // Compose the tweet message with the website URL
    const message = `Look what I created on refabric! ${websiteURL}\n#refabric #aiimagegeneration #fashion`; // Customize your message here

    const hashtags = "refabric,aiimagegeneration,fashion"; // Customize your hashtags here
    const via = "refabric"; // Customize the mention here
    const mention = "refabric"; // Customize the mention here

    const tweetParams = {
      text: message,
      hashtags,
      via,
      related: mention,
      media: imageURL, // Use the image URL as the media parameter
    };

    // Generate the share URL with the tweet details and open it in a new window
    const shareUrl = `https://twitter.com/intent/tweet?${new URLSearchParams(tweetParams)}`;
    window.open(shareUrl, "_blank");
  }

  function handleFacebook(event: any) {
    AnalyticsEvent("share_facebook", "", "click");

    event?.stopPropagation();
    let url;
    if (data.created_data) url = location.protocol + "//" + location.host + data.created_data.slug + "_" + currentImageIdx;
    else url = location.protocol + "//" + location.host + data.slug + "_" + currentImageIdx;

    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  }

  function handleWhatsapp(event: any) {
    AnalyticsEvent("share_whatsapp", "", "click");

    event?.stopPropagation();
    let url;
    if (data.created_data) url = location.protocol + "//" + location.host + data.created_data.slug + "_" + currentImageIdx;
    else url = location.protocol + "//" + location.host + data.slug + "_" + currentImageIdx;

    const message = `Look what I created on refabric! ${url}`; // Customize your message here

    const shareUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(shareUrl, "_blank");
  }

  function handleCopy(event: any) {
    AnalyticsEvent("copy_link", "", "click");
    event?.stopPropagation();
    let url;
    if (data.created_data) url = location.protocol + "//" + location.host + data.created_data.slug + "_" + currentImageIdx;
    else url = location.protocol + "//" + location.host + data.slug + "_" + currentImageIdx;

    clipboardCopy(url);
    setIsCopied(true);
    clipboardCopy(url);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  return (
    <div ref={containerRef} className={clsx("flex flex-col w-[260px] rounded-[26px] bg-bg border border-border", className)} onClick={(event) => event.stopPropagation()}>
      <header className="flex items-center bg-bg-2 text-h6 text-white gap-2.5 p-[15px] rounded-t-[26px]">
        <IconShare className="w-5 h-5" />
        Share this image
      </header>
      <div className="flex flex-col text-h6 text-white p-2.5 gap-2 bg-bg rounded-b-[26px]">
        <ul className="flex cursor-pointer w-full justify-between items-center px-2.5 py-3 hover:bg-bg-2 rounded-2xl" onClick={(event) => handleTwitter(event)}>
          Share with Twitter <IconTwitter className="w-5 h-5" />
        </ul>
        <ul className="flex cursor-pointer w-full justify-between items-center px-2.5 py-3 hover:bg-bg-2 rounded-2xl" onClick={(event) => handleFacebook(event)}>
          Share with Facebook <IconFacebook className="w-5 h-5" />
        </ul>
        <PinterestShareButton
          className="flex cursor-pointer w-full justify-between items-center px-2.5 py-3 hover:bg-bg-2 rounded-2xl"
          imageUrl={data.images ? data.images[currentImageIdx] : data.details[currentImageIdx].image_path}
          description={data.prompt}
          url={
            data.created_data ? location.protocol + "//" + location.host + data.created_data.slug + "_" + currentImageIdx : location.protocol + "//" + location.host + data.slug + "_" + currentImageIdx
          }
        />
        <ul className="flex cursor-pointer w-full justify-between items-center px-2.5 py-3 hover:bg-bg-2 rounded-2xl" onClick={(event) => handleWhatsapp(event)}>
          Share with Whatsapp <IconWhatsapp className="w-5 h-5" />
        </ul>
        <ul className="flex cursor-pointer w-full justify-between items-center px-2.5 py-3 hover:bg-bg-2 rounded-2xl" onClick={(event) => handleCopy(event)}>
          {isCopied ? "Copied!" : "Copy link"} <IconLink className="w-5 h-5" />
        </ul>
      </div>
    </div>
  );
};

export default ShareMenu;
