import React, { useState } from "react";
import Modal from "components/Modal/Modal";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import GetStarted from "./components/GetStarted";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { toggleUserFlow } from "store/commonSlice";

const UserFlow = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState("");
  const { showUserFlow } = useAppSelector((state) => state.common);
  function onClose() {
    dispatch(toggleUserFlow());
    setCurrentStep(0);
  }

  function renderStep() {
    switch (currentStep) {
      case 0:
        return <GetStarted setCurrentStep={setCurrentStep} setEmail={setEmail} onClose={onClose} />;
      case 1:
        return <SignIn email={email} onClose={onClose} setCurrentStep={setCurrentStep}></SignIn>;
      case 2:
        return <SignUp email={email} onClose={onClose} setCurrentStep={setCurrentStep}></SignUp>;
      case 3:
        return <ForgotPassword email={email} onClose={onClose} setCurrentStep={setCurrentStep}></ForgotPassword>;
      default:
        break;
    }
  }

  return (
    <Modal onClose={onClose} show={showUserFlow}>
      <div className="flex flex-col p-5 max-w-[400px] w-full">{renderStep()}</div>
    </Modal>
  );
};

export default UserFlow;
