import React from "react";
import clsx from "clsx";

const Checkbox = (props: any, ref: any) => {
  const { className, children, onClick, containerClassName, ...etc } = props;

  return (
    <label
      className={clsx("checkbox", containerClassName)}
      onClick={(e) => {
        onClick(e);
        e.preventDefault();
      }}
    >
      <input type="checkbox" className="hidden" ref={ref} {...etc} />
      <span className={clsx(className, "flex-center")}>
        <div className="flex-center h-[13px] w-[15px] rounded-sm bg-bg-3">{etc.checked ? <div className="w-[9px] h-[9px] rounded-sm bg-green "></div> : <></>}</div>
      </span>
      {children && <div className="flex">{children}</div>}
    </label>
  );
};

export default React.forwardRef(Checkbox);
