export enum PATHS {
  // HOME = "/",
  EXPLORE = "/",
  NEW_PASSWORD = "/reset-password/:token",
  PRICING = "/pricing",
  ACCOUNT = "/account",
  CREATE = "/create",
  HISTORY = "/history",
  FAVORITES = "/favorites",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_USE = "/terms-of-use",
  POST = "/posts/:id",
  PAYMENT_SUCCESS = "/payment/success/:id",
  PAYMENT_FAIL = "/payment/fail",
  Promo = "/promo/:token",
  EMAIL_VERIFY = "/email-verify/:token",
  WELCOME = "/welcome",
}
