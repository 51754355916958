import Button from "components/Button/Button";
import { IconCopy, IconLink, IconOpenTab } from "icons";
import React, { useState } from "react";
import { useImagePopup } from "../ImagePopupContext";
import { AnalyticsEvent, clipboardCopy } from "utils";
import "./index.css";
import { useIsMobile } from "hooks/useIsMobile";

const PromptCapsule = ({ children }: { children: any }) => {
  return <div className="flex w-fit h-fit px-2 py-1 bg-bg-3 rounded-xl text-h6 text-white">{children}</div>;
};

const PromptBox = ({ _data }: { _data?: any }) => {
  const contextValues = useImagePopup();
  const data = _data ?? contextValues.data;
  const [isPromptCopied, setIsPromptCopied] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const isMobile = useIsMobile();

  function handlePromptCopy() {
    AnalyticsEvent("copy_prompt", "", "click");
    setIsPromptCopied(true);
    clipboardCopy(data?.prompt);
    setTimeout(() => {
      setIsPromptCopied(false);
    }, 1500);
  }

  function handleLinkCopy() {
    AnalyticsEvent("copy_link", "", "click");
    setIsLinkCopied(true);
    clipboardCopy(location.host + data?.slug);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 1500);
  }

  function handleOpenLink() {
    AnalyticsEvent("openlik_prompt", "", "click");

    const url = location.protocol + "//" + location.host + data?.slug;
    window.open(url, "_blank", "noreferrer");
  }

  function renderPrompt() {
    return data?.prompt_array?.map((item: any, idx: number) => <PromptCapsule key={idx}>{item}</PromptCapsule>);
  }

  return (
    <div className="flex flex-col bg-bg-2  p-[15px] gap-2.5 rounded-[10px]">
      <div className="flex flex-wrap gap-2 max-h-[220px] overflow-y-auto pr-2 custom-scrollbar">{renderPrompt()}</div>
      <div className="flex justify-between">
        <div className="flex gap-2.5">
          <Button className="icon-btn bg-bg-3   group/button transition-all duration-0 hover:bg-white" onClick={handlePromptCopy}>
            <IconCopy className="w-5 h-5 text-white group-hover/button:text-black" />
            <span className="hidden group-hover/button:block text-black">{isPromptCopied ? "Copied!" : "Copy Prompt"}</span>
          </Button>
          <Button className="icon-btn bg-bg-3   group/button transition-all duration-0 hover:bg-white " onClick={handleLinkCopy}>
            <IconLink className="w-5 h-5 text-white group-hover/button:text-black" />
            <span className="hidden group-hover/button:block text-black">{isLinkCopied ? "Copied!" : "Copy Link"}</span>
          </Button>
        </div>
        <Button className="icon-btn bg-bg-3   group/button transition-all duration-0 hover:bg-white " onClick={handleOpenLink}>
          <IconOpenTab className="w-5 h-5 text-white group-hover/button:text-black" />
          <span className="hidden group-hover/button:block text-black">Open Link</span>
        </Button>
      </div>
    </div>
  );
};

export default PromptBox;
