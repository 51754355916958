import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useAppSelector } from "store";
import homeService from "api/home.service";
import { useDispatch } from "react-redux";
import { setImagePopupImageIdx, setImagePopupItemId, toggleImagePopup, toggleSearchBar } from "store/commonSlice";
import { useNavigate } from "react-router-dom";
import { guidanceDefault } from "pages/Create/CreateContext";

interface IImagePopupData {
  id: number;
  image_path: string[];
  negative_prompt: string;
  process: string;
  prompt: string;
  seed: string;
  user_id: number;
  dimension: string;
  style_prompt: { prompt: string; title: string };
  upscaled: string;
  creater_info: any;
  width: number;
  height: number;
  cfg_scale: number;
  prompt_array: string[];
  negative_prompt_array: string[];
  details: any;
  slug: string;
}
interface IImagePopupContext {
  data: IImagePopupData;
  currentImageIdx: number;
  onNextImage: () => void;
  onPreviousImage: () => void;
  onSelectImage: (index: number) => void;
  onClose: () => void;
  getData: () => void;
  setData: (data: any) => void;
}

const initialData = {
  id: 0,
  image_path: [],
  negative_prompt: "",
  process: "",
  prompt: "",
  seed: "",
  user_id: "",
  dimension: "",
  style_prompt: { prompt: "", title: "" },
  upscaled: "",
  cfg_scale: guidanceDefault,
  details: [],
};

export const ImagePopupContext = createContext<IImagePopupContext>({} as any);

const ImagePopupProvider = ({ children }: { children: ReactNode }) => {
  const { imagePopupItemId, imagePopupImageIdx } = useAppSelector((state) => state.common);
  const [currentImageIdx, setCurrentImageIdx] = useState<any>(imagePopupImageIdx);

  const [data, setData] = useState<any>(initialData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onNextImage = () => {
    setCurrentImageIdx((prev: any) => (prev + 1 === data?.image_path?.length ? 0 : prev + 1));
  };
  const onPreviousImage = () => {
    setCurrentImageIdx((prev: any) => (prev - 1 < 0 ? data?.image_path?.length - 1 : prev - 1));
  };
  const onSelectImage = (index: number) => setCurrentImageIdx(index);

  const onClose = () => {
    setData(initialData);
    if (window.location.pathname.includes("posts")) navigate("/", { replace: true });
    dispatch(setImagePopupImageIdx(null));
    dispatch(setImagePopupItemId(null));
    dispatch(toggleImagePopup());
    dispatch(toggleSearchBar(true));
  };

  function getData() {
    homeService.getImageDetails({ gid: data?.id }).then((res) => {
      setData(res);
      if (imagePopupImageIdx === null) {
        res?.details.filter((item: any, idx: any) => {
          if (item.first_image === "1") {
            setCurrentImageIdx(idx);
          }
        });
      }
    });
  }

  const contextValue = { data, onClose, currentImageIdx, onNextImage, onPreviousImage, onSelectImage, getData, setData };

  useEffect(() => {
    if (imagePopupItemId !== null)
      homeService.getImageDetails({ gid: imagePopupItemId }).then((res) => {
        setData(res);
        if (imagePopupImageIdx === null) {
          res?.details.filter((item: any, idx: any) => {
            if (item.first_image === "1") {
              setCurrentImageIdx(idx);
            }
          });
        }
      });
  }, [imagePopupItemId]);

  useEffect(() => {
    if (imagePopupImageIdx !== null) {
      setCurrentImageIdx(imagePopupImageIdx);
    }
  }, [imagePopupImageIdx]);

  return (
    <ImagePopupContext.Provider value={contextValue}>
      <div>{children}</div>
    </ImagePopupContext.Provider>
  );
};

export default ImagePopupProvider;

export const useImagePopup = () => useContext(ImagePopupContext);
