import React from "react";
import PromptBox from "./PromptBox";
import { IconGenerate, IconOutpaint, IconVariations } from "icons";
import Button from "components/Button/Button";
import { useImagePopup } from "../ImagePopupContext";
import { useDispatch } from "react-redux";
import { setCreateFormValues } from "store/createSlice";
import { useNavigate } from "react-router-dom";
import { setNoReset, toggleImagePopup } from "store/commonSlice";
import "./index.css";
import { useIsMobile } from "hooks/useIsMobile";
import { AnalyticsEvent } from "utils";
import { useAppSelector } from "store";
import ShopTheLook from "./ShopTheLook";

const AttributeItem = ({ title, value, children }: { children?: React.ReactNode; title: string; value?: string }) => {
  return (
    <div className="flex w-full justify-between">
      <span className="text-headline uppercase text-grey">{title}</span>
      {value && <h6 className="text-h6 text-white">{value}</h6>}
      {children}
    </div>
  );
};

const PromptCapsule = ({ children }: { children: any }) => {
  return <div className="flex w-fit h-fit px-2 py-1 bg-bg-3 rounded-xl text-h6 text-white">{children}</div>;
};

const LeftSide = ({ _data }: { _data?: any }) => {
  const contextValues = useImagePopup();
  const data = _data ?? contextValues.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { user } = useAppSelector((state) => state.common);

  const handleOpenEditor = (event: any) => {
    event?.stopPropagation();
    AnalyticsEvent("open_in_editor", "", "click");
    dispatch(setNoReset(true));
    dispatch(
      setCreateFormValues({
        prompt: data?.prompt,
        negativePrompt: data?.negative_prompt,
        guidanceValue: data?.cfg_scale,
        width: data?.width,
        height: data?.height,
        stylePrompt: data?.style_prompt,
      })
    );
    navigate("/create");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (!isMobile) dispatch(toggleImagePopup());
  };

  function renderNegativePrompt() {
    return data?.negative_prompt_array?.map((item: any, idx: number) => <PromptCapsule key={idx}>{item}</PromptCapsule>);
  }

  function handleCreateVariations() {
    if (!isMobile) dispatch(toggleImagePopup());
    navigate("/create", { state: { createVariations: { data: data, imageIndex: !isMobile ? contextValues.currentImageIdx : _data.currentImageIdx } } });
  }

  function handleOutPaint() {
    if (!isMobile) dispatch(toggleImagePopup());
    navigate("/create", { state: { outpaint: { data: data, imageIndex: !isMobile ? contextValues.currentImageIdx : _data.currentImageIdx } } });
  }

  return (
    <div className="flex flex-col gap-10 w-[440px] h-full lg:gap-5 lg:p-5 lg:w-full">
      <div className="flex flex-col gap-2.5">
        <PromptBox _data={data} />
        {
          <div className="flex gap-2.5">
            {data?.user_id != user.id ? (
              <Button className="btn-primary mobile:w-1/2 w-full whitespace-nowrap" onClick={handleOpenEditor}>
                <IconGenerate className="w-5 h-5" />
                Open in Editor
              </Button>
            ) : (
              <>
                <Button className="btn-primary w-1/2 whitespace-nowrap" onClick={handleCreateVariations}>
                  <IconVariations className="w-5 h-5" />
                  Create Variations
                </Button>
                <Button className="btn-secondary w-1/2 whitespace-nowrap" onClick={handleOutPaint}>
                  <IconOutpaint className="w-5 h-5" />
                  Zoomout
                </Button>
              </>
            )}

            {/* <Button className="btn-secondary w-full">
            <IconSearch class Name="w-5 h-5" />
            Explore Style
          </Button> */}
          </div>
        }
      </div>
      <div className="flex flex-col gap-2.5">
        {/* <div className="flex p-[15px] rounded-[10px] bg-bg-2">
          <ShopTheLook isMobile={isMobile} currentImgPath={data?.image_path[contextValues.currentImageIdx] ?? data?.image_path[data.currentImageIdx]} />
        </div> */}
        <div className="flex p-[15px] rounded-[10px] bg-bg-2">
          <div className="flex flex-col w-full gap-2">
            <span className="text-headline uppercase text-grey">negative prompt</span>
            <div className="flex flex-wrap w-full gap-2 max-h-[100px] overflow-y-auto pr-2 custom-scrollbar">{renderNegativePrompt()}</div>
          </div>
        </div>
        <div className="flex flex-col p-[15px] rounded-[10px] gap-[15px] bg-bg-2">
          <AttributeItem title="model" value={data?.style_prompt.title}></AttributeItem>
          <AttributeItem title="dimensions" value={data?.dimension}></AttributeItem>
          <AttributeItem title="upscaled" value={data?.upscaled}></AttributeItem>
          <AttributeItem title="designed by" value={data?.creater_info?.username}></AttributeItem>
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
