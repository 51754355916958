import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { IconGoogle, IconRocket } from "icons";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import userService from "api/user/user.service";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useAuthToken from "hooks/useAuthToken";
import { useDispatch } from "react-redux";
import { setIsAuthorized, setUser } from "store/commonSlice";
import { MVPrefabricURL, MintgramURL } from "api";
import { AnalyticsEvent } from "utils";
import { PATHS } from "router/config/paths";
import { useLocalStorage } from "hooks/useLocalStorage";
import { v4 as uuidv4 } from "uuid";
import { CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";

const schema = yup
  .object({
    email: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
  })
  .required();

const GetStarted = ({ setCurrentStep, setEmail, onClose }: any) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onValid = (data: any) => {
    userService.checkIsRegistered({ email: data.email }).then((res: any) => {
      setEmail(data.email);
      if (res.is_register) setCurrentStep(1);
      else setCurrentStep(2);
    });
  };
  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get("https://www.googleapis.com/oauth2/v2/userinfo", {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
          },
        })
        .then((response) => {
          const client_id = uuidv4();
          const { email, id } = response.data;
          userService.googleLogin({ email: email, google_token: id, client_id: client_id }).then((res: any) => {
            if (res.return_text === "User created") AnalyticsEvent("google_sign_up", "", "click");
            else if (res.return_text === "Login success") AnalyticsEvent("google_login", "", "click");

            useAuthToken.setAuthTokenFromLocalStorage(res.token);
            dispatch(setUser({ id: res.id }));
            dispatch(setIsAuthorized(true));
            useLocalStorage().setItem(CLIENT_ID_LOCAL_STORAGE_KEY, client_id);
            MVPrefabricURL.setAuthToken(res.token);
            MintgramURL.setAuthToken(res.token);
            if (res.return_text === "User created") window.location.href = PATHS.WELCOME;

            onClose();
          });
        })
        .catch((error) => {
          console.error("Error retrieving user information:", error);
        });
    },
    onError: (error) => console.log("Login Failed:", error),
    prompt: "consent",
  });

  return (
    <>
      <div className="flex flex-col items-center gap-2.5 px-2.5 py-5 ">
        <IconRocket className="text-white" />
        <h3 className="text-h3 text-white">Get Started</h3>
        <div className="max-w-[345px] w-full text-center text-bodyM text-grey">Unlock the full potential of your fashion designs with Refabric`s AI-powered platform.</div>
      </div>
      <div className="flex flex-col items-center gap-5">
        <div className="flex flex-col gap-2.5">
          {/* <GoogleLogin onSuccess={responseMessage} /> */}
          <Button className="btn-google text-h6 text-bg" onClick={() => login()}>
            <IconGoogle />
            Continue with Google
          </Button>
          <span className="max-w-[320px] w-full text-center text-bodySm text-grey">
            By continuing, you agree to our{" "}
            <a href="/terms-of-use" target="_blank" className="text-white underline cursor-pointer">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="/privacy-policy" target="_blank" className="text-white underline">
              Privacy Policy
            </a>
            .
          </span>
        </div>
        <div className="border-b border-white w-full border-opacity-10" />
        <Input onKeyDown={handleKeyPress} error={errors.email?.message} {...register("email")} placeholder="E-mail Address" containerClassName="w-full"></Input>
        <Button className="btn-primary w-full" onClick={onHandleSubmit}>
          Continue with E-mail
        </Button>
      </div>
    </>
  );
};

export default GetStarted;
