import React from "react";
import clsx from "clsx";
import { IconWarning } from "icons";

interface IInput {
  icon?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  error?: string;

  [key: string]: any;
}

const Input = ({ className, containerClassName, icon, error, ...etc }: IInput, ref: any) => {
  return (
    <div className="flex flex-col gap-2.5 w-full">
      <div className={clsx("input-container bg-bg-2 flex flex-row items-center border border-white border-opacity-10 p-[15px] rounded-[10px] ", containerClassName)}>
        <input ref={ref} className={clsx("input", className)} {...etc} />
        {icon}
      </div>
      {error && (
        <span className="flex text-orange text-h6 items-center gap-[5px]">
          <IconWarning className="h-[17px] w-[17px] flex-shrink-0" />
          {error}
        </span>
      )}
    </div>
  );
};

export default React.forwardRef(Input);
