export const IMAGE_SIZE_LIMIT = 5;
export const DEFAULT_MODEL_ID = 6;
export const CLIENT_ID_LOCAL_STORAGE_KEY = "refabric_basic_client_id";
export const WWW_SITE_URL = process.env.REACT_APP_WWW_SITE_URL as string;
export const WWW_SITE_PRICING_URL = WWW_SITE_URL + "/pricing";
export const WWW_SITE_LOGIN = WWW_SITE_URL + "/login";
export const WWW_REFABRIC_BASIC = WWW_SITE_URL + "/refabric-basic-ai-fashion-design-assistant";
export const WWW_DESIGN_SOLUTIONS = WWW_SITE_URL + "/design-solutions";
export const WWW_FASHION_PHOTOGRAPHY = WWW_SITE_URL + "/ai-fashion-photography";
export const WWW_E_COMMERCE_SOLUTIONS = WWW_SITE_URL + "/e-commerce-solutions";
