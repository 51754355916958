import { yupResolver } from "@hookform/resolvers/yup";
import userService from "api/user/user.service";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { IconEyeOff, IconEyeOn } from "icons";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AnalyticsEvent } from "utils";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
    password: yup.string().required("Please enter a password!"),
    confirmPassword: yup
      .string()
      .required("Please enter a password!")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
    name: yup.string().required("Please enter your name!"),
    work: yup.string(),
  })
  .required();

const SignUp = ({ email, onClose }: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email || "",
      password: "",
      confirmPassword: "",
      name: "",
      work: "",
    },
  });

  const onValid = (data: any) => {
    userService
      .register({ name: data.name, password: data.password, email: data.email, work: data.work })
      .then(() => {
        AnalyticsEvent("sign_up", "", "click");
        setShowCheckYourEmail(true);
      })
      .catch((e) => console.log(e));
  };
  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  const CheckYourEmail = () => (
    <div className="flex flex-col gap-2.5">
      <h3 className="text-h3 text-center text-white">Check your e-mail for verification.</h3>
      <span className="text-bodyMd text-grey">We`ve sent you an verification e-mail to activate your account. If you don`t see it, you may need to check your spam folder. </span>
      <Button className="btn-primary w-full mt-2.5" onClick={onClose}>
        Close
      </Button>
    </div>
  );

  return (
    <div className="flex flex-col">
      {showCheckYourEmail ? (
        <CheckYourEmail />
      ) : (
        <>
          <h3 className="text-h3 text-center text-white py-5">Sign Up</h3>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2.5">
              <Input onKeyDown={handleKeyPress} dixsabled error={errors.email?.message} {...register("email")} placeholder="E-mail Address" containerClassName="w-full"></Input>
              <Input
                onKeyDown={handleKeyPress}
                type={showPassword ? "text" : "password"}
                error={errors.password?.message}
                {...register("password")}
                placeholder="Password"
                containerClassName="w-full"
                icon={
                  !showPassword ? (
                    <IconEyeOn className="text-grey hover:text-white cursor-pointer" onClick={() => setShowPassword(true)} />
                  ) : (
                    <IconEyeOff className="text-white hover:text-grey cursor-pointer" onClick={() => setShowPassword(false)} />
                  )
                }
              ></Input>
              <Input
                onKeyDown={handleKeyPress}
                type={showPassword ? "text" : "password"}
                error={errors.confirmPassword?.message}
                {...register("confirmPassword")}
                placeholder="Confirm Password"
                containerClassName="w-full"
                icon={
                  !showPassword ? (
                    <IconEyeOn className="text-grey hover:text-white cursor-pointer" onClick={() => setShowPassword(true)} />
                  ) : (
                    <IconEyeOff className="text-white hover:text-grey cursor-pointer" onClick={() => setShowPassword(false)} />
                  )
                }
              ></Input>
              <Input onKeyDown={handleKeyPress} type="name" error={errors.name?.message} {...register("name")} placeholder="Full Name" containerClassName="w-full"></Input>
              <Input onKeyDown={handleKeyPress} type="work" error={errors.work?.message} {...register("work")} placeholder="Company Name (Optional)" containerClassName="w-full"></Input>
            </div>
            <div className="flex flex-col gap-2.5">
              <Button className="btn-primary w-full" onClick={onHandleSubmit}>
                Sign Up
              </Button>
              <span className="max-w-[320px] w-full text-center text-bodySm text-grey">
                By continuing, you agree to our{" "}
                <a href="/terms-of-use" target="_blank" className="text-white underline cursor-pointer">
                  Terms of Use
                </a>{" "}
                and{" "}
                <a href="/privacy-policy" target="_blank" className="text-white underline">
                  Privacy Policy
                </a>
                .
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
