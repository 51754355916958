import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import userService from "api/user/user.service";
import useAuthToken from "hooks/useAuthToken";
import { setIsAuthorized, setUser } from "store/commonSlice";
import { useDispatch } from "react-redux";
import { IconEyeOff, IconEyeOn, IconWarning } from "icons";
import { MVPrefabricURL, MintgramURL } from "api";
import Checkbox from "components/CheckBox/Checkbox";
import { AnalyticsEvent } from "utils";
import { useLocalStorage } from "hooks/useLocalStorage";
import { v4 as uuidv4 } from "uuid";
import { CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";
import ReactGA from "react-ga4";

const schema = yup
  .object({
    email: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
    password: yup.string().required("Please enter a password!"),
  })
  .required();

const SignIn = ({ email, onClose, setCurrentStep }: any) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email || "",
      password: "",
    },
  });

  const onValid = (data: any) => {
    const client_id = uuidv4();
    userService
      .login({ email: data.email, password: data.password, client_id: client_id })
      .then((res: any) => {
        AnalyticsEvent("log_in", "", "click");
        if (isRememberMeChecked) useAuthToken.setAuthTokenFromLocalStorage(res.token);
        dispatch(setUser({ id: res.id }));
        ReactGA.set({ userId: res.id });
        useLocalStorage().setItem(CLIENT_ID_LOCAL_STORAGE_KEY, client_id);
        MVPrefabricURL.setAuthToken(res.token);
        MintgramURL.setAuthToken(res.token);
        dispatch(setIsAuthorized(true));
        onClose();
      })
      .catch((e) => {
        if (!e.return_status) {
          setPasswordError(true);
          setValue("password", "");
        }
      });
  };

  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  return (
    <div className="flex flex-col">
      <h3 className="text-h3 text-center text-white py-5">Sign In</h3>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2.5">
          <Input onKeyDown={handleKeyPress} disabled error={errors.email?.message} {...register("email")} placeholder="E-mail Address" containerClassName="w-full"></Input>
          <Input
            onKeyDown={handleKeyPress}
            type={showPassword ? "text" : "password"}
            error={errors.password?.message}
            {...register("password")}
            placeholder="Password"
            containerClassName="w-full"
            icon={
              !showPassword ? (
                <IconEyeOn className="text-grey hover:text-white cursor-pointer" onClick={() => setShowPassword(true)} />
              ) : (
                <IconEyeOff className="text-white hover:text-grey cursor-pointer" onClick={() => setShowPassword(false)} />
              )
            }
          ></Input>
          {passwordError && (
            <span className="flex text-orange text-h6 items-center gap-[5px]">
              <IconWarning className="h-[17px] w-[17px] flex-shrink-0" />
              Invalid password. Please try again.
            </span>
          )}
          <div className="flex items-center justify-between px-2.5">
            <Checkbox containerClassName="flex gap-2.5 items-center" checked={isRememberMeChecked} onClick={() => setIsRememberMeChecked((prev) => !prev)}>
              <span className="text-bodySm text-white">Remember me</span>
            </Checkbox>
            <span className="cursor-pointer text-right text-bodySm text-green underline" onClick={() => setCurrentStep(3)}>
              Forgot Password?
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2.5">
          <Button className="btn-primary w-full" onClick={onHandleSubmit}>
            Sign In
          </Button>
          <span className="max-w-[320px] w-full text-center text-bodySm text-grey">
            By continuing, you agree to our <span className="text-white underline">Terms of Use</span> and <span className="text-white underline">Privacy Policy</span>.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
