/* eslint-disable react/no-unescaped-entities */
import userService from "api/user/user.service";
import clsx from "clsx";
import { useClickOutside } from "hooks/useClickOutside";
import { IconDownload, IconPremium } from "icons";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { setSnackbar, showSnackbar } from "store/commonSlice";
import { downloadImages, handleManageSubscription } from "utils";
import loadingAnimationJson from "animations/loading-animation.json";

const DownloadMenu = ({ data, uid, className, setIsOpen }: { data: any; uid: string; className: string; setIsOpen: any }) => {
  const { user } = useAppSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LoadingAnimation = <Lottie animationData={loadingAnimationJson} loop={true} className="w-6 h-6" />;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isEligibleForStandard, setIsEligibleForStandard] = useState(false);
  const [isEligibleForHigh, setIsEligibleForHigh] = useState(false);
  const [isDownloadingStandard, setIsDownloadingStandard] = useState(false);
  const [isDownloadingHigh, setIsDownloadingHigh] = useState(false);

  useClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (user.id !== "")
      userService.getUserInfo({ uid: uid }).then((res: any) => {
        setIsEligibleForStandard(!!res.user_info.is_standard_download);
        setIsEligibleForHigh(!!res.user_info.is_high_download);
      });
  }, []);

  function errorHighDownload() {
    setIsDownloadingHigh(false);

    dispatch(
      setSnackbar({
        show: true,
        icon: IconPremium,
        message: (
          <span>
            Your current plan doesn't cover High Resolution Download feature.{" "}
            <span
              className="cursor-pointer underline"
              onClick={async () => {
                if (user.id === "") navigate("/pricing");
                else handleManageSubscription();
                dispatch(showSnackbar(false));
              }}
            >
              Upgrade
            </span>{" "}
            to "Professional" plan to unlock!
          </span>
        ),
      })
    );
  }
  function errorStandardDownload() {
    setIsDownloadingHigh(false);

    dispatch(
      setSnackbar({
        show: true,
        icon: IconPremium,
        message: (
          <span>
            Your current plan doesn't cover Standard Resolution Download feature.{" "}
            <span
              className="cursor-pointer underline"
              onClick={async () => {
                if (user.id === "") navigate("/pricing");
                else handleManageSubscription();
                dispatch(showSnackbar(false));
              }}
            >
              Upgrade
            </span>{" "}
            to "Starter" or higher plan to unlock!
          </span>
        ),
      })
    );
  }

  async function handleHighResolution(event: any) {
    event?.stopPropagation();

    if (user.id === "") {
      errorHighDownload();

      return;
    }

    try {
      setIsDownloadingHigh(true);

      await userService.downloadHighResolution(data.high_download_image).then(({ data }) => {
        downloadImages([data.img_url]);
      });
    } catch (error) {
      errorHighDownload();
    }
    setIsDownloadingHigh(false);
  }

  async function handleStandardResolution(event: any) {
    event?.stopPropagation();

    if (user.id === "") {
      errorStandardDownload();

      return;
    }

    try {
      setIsDownloadingStandard(true);

      await userService.downloadStandardResolution(data.download_image).then(() => {
        downloadImages([data.download_image]);
      });
    } catch (error) {
      errorStandardDownload();
    }
    setIsDownloadingStandard(false);
  }

  return (
    <div ref={containerRef} className={clsx("w-[260px] flex flex-col rounded-[26px] border border-border", className)} onClick={(event) => event.stopPropagation()}>
      <div className="flex text-white p-[15px] bg-bg-2 gap-2.5 rounded-t-[26px]">
        <IconDownload className="h-5 w-5" />
        <h6 className="text-h6">Download</h6>
      </div>
      <div className="flex flex-col p-2.5 gap-2 bg-bg rounded-b-[26px]">
        <div
          className={clsx("cursor-pointer flex items-center justify-between py-2 px-2.5 hover:bg-bg-2 rounded-[16px]", !isEligibleForStandard || user.id === "" ? "text-grey" : "text-white")}
          onClick={(event) => handleStandardResolution(event)}
        >
          <div className="flex flex-col gap-[3px]">
            <h6 className="text-h6">Standard</h6>
            <span className="text-bodySm text-grey">{data.width + "x" + data.height}</span>
          </div>
          {isDownloadingStandard ? (
            LoadingAnimation
          ) : (
            <span className="flex-center bg-green h-fit p-[3px] rounded-full">
              <IconPremium className="w-[18px] h-[18px]  text-black" />
            </span>
          )}
        </div>
        <div
          className={clsx("cursor-pointer flex items-center justify-between py-2 px-2.5 hover:bg-bg-2 rounded-[16px]", !isEligibleForHigh || user.id === "" ? "text-grey" : "text-white")}
          onClick={(event) => handleHighResolution(event)}
        >
          <div className="flex flex-col gap-[3px]">
            <h6 className="text-h6 ">High Resolution</h6>
            <span className="text-bodySm text-grey">{data.width * 2 + "x" + data.height * 2}</span>
          </div>
          {isDownloadingHigh ? (
            LoadingAnimation
          ) : (
            <span className="flex-center bg-green h-fit p-[3px] rounded-full">
              <IconPremium className="w-[18px] h-[18px]  text-black" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadMenu;
