import clsx from "clsx";
import Button from "components/Button/Button";
import { useImagePopup } from "components/ImagePopup/ImagePopupContext";
import { IconDownload, IconFeatured, IconRightArrow, IconShare, IconTrash, Iconlike } from "icons";
import React, { useEffect, useRef, useState } from "react";
import { AnalyticsEvent } from "utils";
import ShareMenu from "./ShareMenu";
import createService from "api/create/create.service";
import { useAppSelector } from "store";
import userService from "api/user/user.service";
import { useDispatch } from "react-redux";
import { setShowUserFlow, toggleImagePopup } from "store/commonSlice";
import DownloadMenu from "./DownloadMenu";
import { useClickOutside } from "hooks/useClickOutside";

const Image = () => {
  const { onNextImage, onPreviousImage, data, setData, currentImageIdx, getData } = useImagePopup();
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.common);
  const [isOpen, setIsOpen] = useState(false);
  const [isLikedArray, setIsLikedArray] = useState<any>([]);
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
  const deletePopup = React.useRef<HTMLDivElement>(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useClickOutside(deletePopup, () => {
    setShowDeletePopup(false);
  });

  const findFeaturedIdx = () => {
    const idx = data?.details.findIndex((item: any) => item.first_image === "1");

    return idx;
  };
  const [featuredIdx, setFeaturedIdx] = useState<any>(findFeaturedIdx());
  const imageRef = useRef<HTMLImageElement>(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    const modalElement = document.getElementById("modal") as HTMLElement | null;
    const imageContainerElement = document.getElementById("image-container") as HTMLElement | null;

    if (!modalElement || !imageContainerElement) return;

    const imageElement = imageRef.current;
    if (!imageElement) return;

    const adjustImageSize = () => {
      const modalWidth = modalElement.clientWidth;
      const modalHeight = modalElement.clientHeight;
      const imageAspectRatio = imageElement.naturalWidth / imageElement.naturalHeight;

      let imageWidth = modalWidth;
      let imageHeight = modalWidth / imageAspectRatio;
      if (imageHeight > modalHeight - 130) {
        imageHeight = modalHeight - 130;
        imageWidth = (modalHeight - 130) * imageAspectRatio;
      }
      if (imageWidth > modalWidth - 520) {
        imageWidth = modalWidth - 520;
        imageHeight = (modalWidth - 520) / imageAspectRatio;
      }

      imageContainerElement.style.width = `${imageWidth}px`;
      imageContainerElement.style.height = `${imageHeight}px`;
      imageElement.style.width = `${imageWidth}px`;
      imageElement.style.height = `${imageHeight}px`;
    };

    adjustImageSize();

    const resizeHandler = () => adjustImageSize();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [isImageLoaded]);

  const handleLike = (event: any) => {
    event?.stopPropagation();
    if (user.id === "") {
      dispatch(toggleImagePopup());
      dispatch(setShowUserFlow(true));
    } else {
      if (isLikedArray.includes(currentImageIdx))
        userService
          .unlike({ post_id: data.details[currentImageIdx].id, user_id: user.id })
          .then((res: any) => {
            if (res[0]?.response_data.response_message === "Marked") setIsLikedArray((prev: any) => prev.filter((item: any) => item !== currentImageIdx));
          })
          .catch((e) => console.log(e));
      else
        userService
          .like({ post_id: data.details[currentImageIdx].id, user_id: user.id })
          .then((res: any) => {
            if (res[0]?.response_data.response_message === "Marked") setIsLikedArray((prev: any) => [...prev, currentImageIdx]);
          })
          .catch((e) => console.log(e));
    }
  };

  function handleDelete() {
    createService
      .deleteImage({ image_id: data?.details[currentImageIdx]?.id, user_id: user.id })
      .then(() => {
        // getData();
        setData((prev: any) => ({ ...prev, image_path: prev.image_path.filter((item: any, index: number) => currentImageIdx !== index) }));
        setShowDeletePopup(false);
      })
      .catch((e) => console.log(e));
  }

  async function onMakeFeatured(idx: any) {
    createService
      .makeFeatured({
        id: data.details[idx].id,
        image_path: data.details[idx].image_path,
      })
      .then(() => setFeaturedIdx(idx));
  }
  const getLikedImagesIndexes = () => {
    return data?.details?.map((item: any, index: number) => (item.is_liked === true ? index : -1)).filter((index: any) => index !== -1);
  };
  useEffect(() => {
    setFeaturedIdx(findFeaturedIdx());
    setIsLikedArray(getLikedImagesIndexes());
  }, [data]);

  return (
    <div id="image-container" className={`relative group`}>
      {!data.image_path[currentImageIdx] ? (
        <div className="flex w-[512px] h-[512px] bg-white bg-opacity-10 rounded-2xl"></div>
      ) : (
        <>
          {isOpen && <ShareMenu data={data} currentImageIdx={currentImageIdx} className="absolute z-50 right-[60px] top-[60px]" setIsOpen={setIsOpen} />}
          {data?.user_id === user.id ? (
            featuredIdx === "" && data?.details[currentImageIdx]?.first_image === "1" ? (
              <div className="absolute  flex h-fit mt-auto p-2.5 inset-0">
                <div className="flex rounded-full p-[6px] h-7 gap-[6px] items-center group/button  bg-white text-black">
                  <IconFeatured className=" w-4 h-4 text-black" />
                  <span className="hidden group-hover/button:block text-headline text-black">FEATURED</span>
                </div>
              </div>
            ) : featuredIdx === currentImageIdx ? (
              <div className="absolute  flex h-fit mt-auto p-2.5 inset-0">
                <div className="flex rounded-full p-[6px] h-7 gap-[6px] items-center group/button  bg-white text-black">
                  <IconFeatured className=" w-4 h-4 text-black" />
                  <span className="hidden group-hover/button:block text-headline text-black">FEATURED</span>
                </div>
              </div>
            ) : (
              <div className="absolute flex h-fit mt-auto p-2.5 inset-0">
                <div
                  className="flex cursor-pointer h-[28px] text-white hover:bg-white hover:text-black rounded-full items-center px-[6px] gap-[6px] bg-border"
                  onClick={(e) => {
                    e.stopPropagation();
                    onMakeFeatured(currentImageIdx);
                  }}
                >
                  <IconFeatured className="w-4 h-4" />
                  <span className="text-headline">MAKE FEATURED</span>
                </div>
              </div>
            )
          ) : (
            <></>
          )}

          <img ref={imageRef} loading="lazy" src={data.image_path[currentImageIdx]} alt="Image" className="pointer-events-none peer object-contain rounded-[10px]" onLoad={handleImageLoad} />

          <div className="flex absolute top-0 right-0 flex-col p-2.5 gap-2.5">
            <Button className="icon-btn bg-green transition-all duration-200 hover:bg-purple" onClick={handleLike}>
              <Iconlike className={clsx("w-5 h-5", isLikedArray.includes(currentImageIdx) ? "fill-black" : "fill-none")} />
            </Button>
            <Button
              className={clsx("icon-btn bg-green transition-all duration-200 hover:bg-purple", isOpen ? "pointer-events-none" : "")}
              onClick={() => {
                AnalyticsEvent("share_design", "", "click");
                setIsOpen(true);
              }}
            >
              <IconShare className={"w-5 h-5"} />
            </Button>
            <Button className="icon-btn bg-green transition-all duration-200 hover:bg-purple" onClick={() => setIsDownloadMenuOpen(true)}>
              <IconDownload className={"w-5 h-5"} />
            </Button>
            {isDownloadMenuOpen && <DownloadMenu data={data.details[currentImageIdx]} setIsOpen={setIsDownloadMenuOpen} className="absolute z-50 top-40 right-2.5 " uid={user.id} />}
          </div>
          <div className=" flex absolute top-1/2 bottom-1/2 left-0  p-2.5 z-10  items-center justify-between opacity-0 transition-opacity duration-300 peer-hover:opacity-100 group-hover:opacity-100">
            <Button className="flex-center h-fit w-fit p-[15px] rounded-full bg-black bg-opacity-60 cursor-pointer" onClick={onPreviousImage}>
              <IconRightArrow className="text-white w-5 h-5 rotate-180" />
            </Button>
          </div>
          <div className=" flex absolute top-1/2 bottom-1/2 right-0  p-2.5 z-10  items-center justify-between opacity-0 transition-opacity duration-300 peer-hover:opacity-100 group-hover:opacity-100">
            <Button className="flex-center h-fit w-fit p-[15px] rounded-full bg-black bg-opacity-60 cursor-pointer" onClick={onNextImage}>
              <IconRightArrow className="text-white w-5 h-5 " />
            </Button>
          </div>

          {showDeletePopup && (
            <div ref={deletePopup} className="absolute right-2 bottom-16 z-50 max-w-[80%] flex flex-col gap-2.5">
              <div className="flex flex-col border border-border rounded-xl pointer-events-none">
                <div className="flex gap-2.5 bg-bg-2 text-white p-[15px] rounded-t-xl">
                  <IconTrash className="w-5 h-5" />
                  <h6 className="text-h6">Delete Image</h6>
                </div>
                <div className="flex bg-bg px-5 py-[22px] text-white rounded-b-xl">
                  <h6 className="text-h6 ">Are you sure you want to delete this image?</h6>
                </div>
              </div>
              <div className="flex flex-col gap-2.5 p-2.5 bg-bg rounded-xl">
                <Button className="btn-warning" onClick={handleDelete}>
                  Delete Image
                </Button>
                <Button
                  className="btn-secondary"
                  onClick={(event) => {
                    event?.stopPropagation();
                    setShowDeletePopup(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}

          {data?.user_id === user.id && (
            <div className="flex absolute bottom-0 right-0 flex-col p-2.5 gap-2.5">
              <Button
                className="icon-btn btn-secondary bg-green transition-all duration-200 hover:bg-purple"
                onClick={(event) => {
                  event?.stopPropagation();
                  setShowDeletePopup(true);
                }}
              >
                <IconTrash className={clsx("w-5 h-5", isLikedArray.includes(currentImageIdx) ? "fill-black" : "fill-none")} />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Image;
